import {
    isStaleMachineSession,
    stepAutomationsState,
    stepCloneState,
    stepDevcontainerState,
    stepStartState,
    StepState,
} from "@/components/environments/environment-start-steps-state";
import { Button } from "@/components/flexkit/Button";
import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import { Heading1 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { useEnvironmentComputeDescription } from "@/hooks/use-environment-compute-description";
import {
    getRepoUrlFromInitializer,
    type PlainEnvironment,
    type PlainRunnerEnvironmentClass,
    toPlainRunnerEnvironmentClass,
    useStartEnvironment,
    useUpdateEnvironment,
} from "@/queries/environment-queries";
import { v4 as uuidv4 } from "uuid";
import { useGetEnvironmentClass } from "@/queries/runner-configuration-queries.ts";
import { type AuthenticatedWithRunnerResponse, useIsUserAuthenticatedWithRunner } from "@/queries/runner-queries.ts";
import { SCMAuthenticationModal } from "@/routes/environments/create/SCMAuthentication.tsx";
import { EnvironmentPhase, EnvironmentStatus_ContentPhase } from "gitpod-next-api/gitpod/v1/environment_pb.ts";
import { type FC, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { formatError } from "@/utils/errors";
import { StartSequenceStepIcon } from "@/assets/icons/geist/StartSequenceStepIcon";

export const StepStart: FC<{ environment: PlainEnvironment }> = ({ environment }) => {
    const navigate = useNavigate();

    const state = useMemo(() => stepStartState(environment), [environment]);

    const title = useMemo(() => {
        switch (state) {
            case StepState.Failure:
            case StepState.Waiting:
            case StepState.Empty:
                return "Start";
            case StepState.Running:
                return "Starting";
            case StepState.Success:
                return "Started";
        }
    }, [state]);

    const description = useEnvironmentComputeDescription(environment);
    const subtitle = [description?.runnerName, description?.clazz].filter(Boolean).join("\u00A0\u00A0•\u00A0\u00A0");

    const text = useMemo(() => {
        if (isStaleMachineSession(environment)) {
            return;
        }
        return [environment.status?.machine?.failureMessage, environment.status?.machine?.warningMessage]
            .filter(Boolean)
            .join("\n");
    }, [environment]);

    const onViewLogs = useMemo(() => {
        if (state == StepState.Waiting) {
            return;
        }
        return () => {
            // TODO: Deep-link to the relevant log group once we have NEXT-1326 in place
            navigate(`/details/${environment.id}/logs`);
        };
    }, [navigate, environment, state]);

    return <Step title={`${title} machine`} subtitle={subtitle} text={text} state={state} onViewLogs={onViewLogs} />;
};

export const StepClone: FC<{ environment: PlainEnvironment }> = ({ environment }) => {
    const navigate = useNavigate();

    const state = useMemo(() => stepCloneState(environment), [environment]);

    const title = useMemo(() => {
        switch (state) {
            case StepState.Failure:
            case StepState.Waiting:
            case StepState.Empty:
                return "Clone repository";
            case StepState.Running:
                return "Cloning repository";
            case StepState.Success:
                return "Cloned repository";
        }
    }, [state]);

    const text = useMemo(() => {
        if (isStaleMachineSession(environment)) {
            return;
        }
        return [environment.status?.content?.failureMessage, environment.status?.content?.warningMessage]
            .filter(Boolean)
            .join("\n");
    }, [environment]);

    const repoURL = useMemo(() => {
        return getRepoUrlFromInitializer(environment.spec?.content?.initializer);
    }, [environment]);

    const isFailed = useMemo(() => {
        if (isStaleMachineSession(environment)) {
            return;
        }
        return environment.status?.content?.phase == EnvironmentStatus_ContentPhase.FAILED;
    }, [environment]);
    const { data: authCheckResponse } = useIsUserAuthenticatedWithRunner(environment.metadata?.runnerId, repoURL, {
        refetchUntilAuthenticated: true,
        sessionId: environment.spec?.machine?.session,

        // Auth check should be polled only when content is in a failed state.
        enabled: isFailed,
    });

    const isAuthenticationRequired = isFailed && authCheckResponse?.type == "AuthenticationRequired";
    const [showScmAuthModal, setShowScmAuthModal] = useState<SCMAuthenticationModalProps | undefined>();

    const { data: clazz } = useGetEnvironmentClass(environment.spec?.machine?.class);
    const onViewLogs = useMemo(() => {
        if (state == StepState.Waiting) {
            return;
        }
        return () => {
            // TODO: Deep-link to the relevant log group once we have NEXT-1326 in place
            navigate(`/details/${environment.id}/logs`);
        };
    }, [navigate, environment, state]);
    const startEnvironment = useStartEnvironment();

    return (
        <>
            <Step
                title={title}
                subtitle={environment.status?.content?.git?.cloneUrl}
                text={text}
                actionButton={isAuthenticationRequired ? "Re-Authenticate" : undefined}
                onClickActionButton={() => {
                    if (!authCheckResponse || !clazz) {
                        return;
                    }
                    setShowScmAuthModal({
                        repoURL: repoURL || "",
                        clazz: toPlainRunnerEnvironmentClass(clazz),
                        authResponse: authCheckResponse,
                        onClose: () => setShowScmAuthModal(undefined),
                        onAuthSuccess: () => {
                            setShowScmAuthModal(undefined);
                        },
                    });
                }}
                state={state}
                onViewLogs={onViewLogs}
            />
            {showScmAuthModal && (
                <AuthenticationModal
                    {...showScmAuthModal}
                    onClose={() => setShowScmAuthModal(undefined)}
                    onAuthSuccess={async () => {
                        setShowScmAuthModal(undefined);

                        // Restart environment when authentication is successful and the environment is stopped.
                        if (environment.status?.phase === EnvironmentPhase.STOPPED) {
                            await startEnvironment.mutateAsync(environment.id);
                        }
                    }}
                />
            )}
        </>
    );
};

export const StepStartDevcontainer: FC<{ environment: PlainEnvironment }> = ({ environment }) => {
    const navigate = useNavigate();

    const updateEnvironment = useUpdateEnvironment();
    const { toast } = useToast();
    const { state, canRebuild } = useMemo(() => stepDevcontainerState(environment), [environment]);

    const title = useMemo(() => {
        switch (state) {
            case StepState.Failure:
            case StepState.Waiting:
            case StepState.Empty:
                return "Start dev container";
            case StepState.Running:
                return "Starting dev container";
            case StepState.Success:
                return "Started dev container";
        }
    }, [state]);

    const text = useMemo(() => {
        if (isStaleMachineSession(environment)) {
            return;
        }
        return [environment.status?.devcontainer?.failureMessage, environment.status?.devcontainer?.warningMessage]
            .filter(Boolean)
            .join("\n");
    }, [environment]);

    const [hint, actionButton] = canRebuild
        ? ["The dev container file is out of sync with the current environment", "Rebuild"]
        : [undefined, undefined];

    const onClickActionButton = useCallback(async () => {
        await updateEnvironment.mutateAsync(
            {
                req: {
                    environmentId: environment.id,
                    spec: {
                        devcontainer: {
                            session: uuidv4(),
                        },
                    },
                },
            },
            {
                onError(error) {
                    toast({
                        title: "Failed to trigger rebuild of the dev container",
                        description: formatError(error),
                    });
                },
            },
        );
    }, [toast, updateEnvironment, environment.id]);

    const onViewLogs = useMemo(() => {
        if (state == StepState.Waiting) {
            return;
        }
        return () => {
            // TODO: Deep-link to the relevant log group once we have NEXT-1326 in place
            navigate(`/details/${environment.id}/logs`);
        };
    }, [navigate, environment, state]);

    const subtitle = useMemo(() => {
        const contentPath = environment.status?.content?.contentLocationInMachine || "";
        const devcontainerPath = environment.status?.devcontainer?.devcontainerFilePath || "";
        return devcontainerPath.slice(contentPath.length).replace(/^\//, "");
    }, [environment]);

    return (
        <Step
            title={title}
            subtitle={subtitle}
            text={text}
            hint={hint}
            actionButton={actionButton}
            actionButtonLoading={updateEnvironment.isPending}
            onClickActionButton={onClickActionButton}
            state={state}
            onViewLogs={onViewLogs}
            hasNext={false}
        />
    );
};

export const StepAutomations: FC<{ environment: PlainEnvironment }> = ({ environment }) => {
    const state = useMemo(() => stepAutomationsState(environment), [environment]);

    const title = useMemo(() => {
        switch (state) {
            case StepState.Failure:
            case StepState.Waiting:
            case StepState.Empty:
                return "Load Automations";
            case StepState.Running:
                return "Loading Automations";
            case StepState.Success:
                return "Loaded Automations";
        }
    }, [state]);

    const subtitle = useMemo(() => {
        if (state == StepState.Empty) {
            return "No Automations file";
        }
        return environment.status?.automationsFile?.automationsFilePath;
    }, [state, environment.status?.automationsFile?.automationsFilePath]);

    const text = useMemo(() => {
        if (isStaleMachineSession(environment)) {
            return;
        }
        return environment.status?.automationsFile?.failureMessage;
    }, [environment]);
    return <Step title={title} subtitle={subtitle} text={text} state={state} />;
};

const Step: FC<
    {
        title: string;
        subtitle?: string;
        text?: string;
        hint?: string;
        actionButton?: string;
        actionButtonLoading?: boolean;
        onClickActionButton?: () => void;
        state: StepState;
        onViewLogs?: () => void;
        hasNext?: boolean;
    } & PropsWithClassName
> = ({
    title,
    subtitle,
    text,
    hint,
    actionButton,
    actionButtonLoading,
    onClickActionButton,
    state,
    onViewLogs,
    className,
    hasNext = true,
}) => {
    const secondLine = text;
    return (
        <div className={cn("group flex flex-row gap-2 last:hover:rounded-b-xl", className)}>
            <div className="ml-[24px] flex w-[40px] flex-col items-center py-1">
                <StepIcon state={state} />
                {hasNext && (
                    <div className="mt-2 h-full w-0.5 grow border-r-[2.5px] border-dotted border-gray-600/20" />
                )}
            </div>
            <div className="flex min-h-14 grow flex-col truncate">
                <div className="mb-2 flex h-8 w-full flex-row items-center gap-2">
                    {/* step title */}
                    <div className="inline-flex grow gap-2 truncate">
                        <Heading1 className="text-base">{title}</Heading1>
                        {subtitle && (
                            <span className="min-w-0 truncate text-base text-content-secondary">{subtitle}</span>
                        )}
                    </div>
                    <div className="float-end flex gap-2 pr-4">
                        <div className={cn("hidden", onViewLogs && "group-hover:block")}>
                            <Button size="sm" variant="secondary" onClick={onViewLogs} data-track-label="true">
                                Logs
                            </Button>
                        </div>
                        {actionButton && onClickActionButton && (
                            <div>
                                <Button
                                    size="sm"
                                    variant="primary"
                                    loading={actionButtonLoading}
                                    onClick={onClickActionButton}
                                    data-track-label="true"
                                >
                                    {actionButton}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                {hint && (
                    <div className="-mt-4 h-fit max-w-full text-left">
                        <Text className="text-wrap text-sm text-content-secondary">{hint}</Text>
                    </div>
                )}
                {secondLine && (
                    <div className="h-fit max-w-full pb-2">
                        <Text className="text-wrap font-mono text-sm text-content-secondary">{secondLine}</Text>
                    </div>
                )}
                {hasNext && <div className="mr-4 border-t-[1.5px] border-dotted border-gray-600/20 pb-2" />}
            </div>
        </div>
    );
};

const StepIcon = ({ state }: { state: StepState }) => {
    return (
        <div className="flex min-h-6 min-w-6 items-center justify-center">
            <StartSequenceStepIcon state={state} />
        </div>
    );
};

type SCMAuthenticationModalProps = {
    repoURL: string;
    authResponse: AuthenticatedWithRunnerResponse;
    clazz: PlainRunnerEnvironmentClass;
    onClose: () => void;
    onAuthSuccess: () => void;
};
const AuthenticationModal: FC<SCMAuthenticationModalProps> = (p) => {
    const patSupported = useMemo(() => {
        return p.authResponse.type === "AuthenticationRequired" && p.authResponse.patSupported;
    }, [p]);
    const authUrl = useMemo(() => {
        return p.authResponse.type === "AuthenticationRequired" ? p.authResponse.url : "";
    }, [p]);
    const scmId = useMemo(() => {
        return p.authResponse.type === "AuthenticationRequired" ? p.authResponse.scmId : "";
    }, [p]);

    return (
        <div>
            <SCMAuthenticationModal
                repoURL={p.repoURL}
                authenticationUrl={authUrl}
                patSupported={patSupported}
                scmId={scmId}
                onClose={p.onClose}
                onClickContinue={p.onAuthSuccess}
                clazz={p.clazz}
            />
        </div>
    );
};
