import { Button } from "@/components/flexkit/Button";
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { useCallback, useId, useState, type FC, type FormEvent } from "react";
import { useCreateProjectFromEnvironment } from "@/queries/project-queries";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { formatError } from "@/utils/errors";
import { InputField } from "@/components/podkit/forms/InputField";
import { Input } from "@/components/podkit/forms/Input";

export const CreateProjectFromEnvironmentModal: FC<{
    environmentId: string;
    onClose: () => void;
    onSuccess?: () => void;
}> = ({ environmentId, onClose, onSuccess }) => {
    const { toast } = useToast();

    const [projectName, setProjectName] = useState<string>();
    const inputNameID = useId();

    const createProjectFromEnvironment = useCreateProjectFromEnvironment();

    const handleOpenChange = useCallback(
        (nextOpen: boolean) => {
            if (!nextOpen) {
                onClose();
            }
        },
        [onClose],
    );

    const handleSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (!projectName) {
                return;
            }

            createProjectFromEnvironment.mutate(
                {
                    projectName,
                    environmentId,
                },
                {
                    onSuccess: () => {
                        onClose();
                        onSuccess?.();
                    },
                    onError: (e) => {
                        toast({
                            title: "Failed to create project from environment",
                            description: formatError(e),
                        });
                    },
                },
            );
        },
        [createProjectFromEnvironment, toast, onClose, onSuccess, projectName, environmentId],
    );

    return (
        <Dialog open onOpenChange={handleOpenChange}>
            <DialogContent className="max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Create project from environment</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                <form onSubmit={handleSubmit} className="overflow-hidden">
                    <DialogBody className="flex max-w-full flex-col gap-2 overflow-hidden">
                        <InputField label="Project name" id={inputNameID}>
                            <Input
                                id={inputNameID}
                                type="text"
                                value={projectName || ""}
                                placeholder="Ex: My Project"
                                onChange={(e) => setProjectName(e.target.value.trimStart().replace(/\s+/g, " "))}
                            />
                        </InputField>
                        <div className="px-1 text-sm text-content-secondary">
                            Your project will be shared with everyone in your organization. You can change that anytime
                            in the project settings.
                        </div>
                    </DialogBody>

                    <DialogFooter className="sm:justify-end">
                        <DialogClose asChild>
                            <Button type="button" variant="secondary">
                                Close
                            </Button>
                        </DialogClose>
                        <Button
                            type="submit"
                            variant="primary"
                            loading={createProjectFromEnvironment.isPending}
                            disabled={!projectName}
                        >
                            Create
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};
