import { IconCheckFill } from "@/assets/icons/geist/IconCheckFill";
import { IconWarning } from "@/assets/icons/geist/IconWarning";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Button } from "@/components/flexkit/Button";
import { Card } from "@/components/podkit/Card";
import { Input } from "@/components/podkit/forms/Input";
import { Label } from "@/components/podkit/forms/Label";
import { Dialog, DialogContent } from "@/components/podkit/modal/Modal";
import { Heading1, Heading2 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { SourceControlProviderIcon } from "@/components/runners/details/SourceControlProviderIcon";
import { type SCMAuthenticationStage, useSCMAuthentication } from "@/hooks/use-scm-authentication.ts";
import type { PlainRunnerEnvironmentClass } from "@/queries/environment-queries";
import { useCreateAndVerifyHostAuthenticationTokenPAT } from "@/queries/runner-configuration-queries";
import { useRunner } from "@/queries/runner-queries";
import { formatError } from "@/utils/errors";
import { Code } from "@connectrpc/connect";
import { getScmProviderName } from "frontend-shared/local-runner";
import { type FC, type PropsWithChildren, useCallback, useId, useMemo, useState } from "react";

export type SCMAuthenticationProps = {
    stage: SCMAuthenticationStage;
    verificationError?: Error;
    clazz?: PlainRunnerEnvironmentClass;
    repoURL: string;
    // If the authentication URL isn't provided it means the runner doesn't support OAuth
    authenticationUrl?: string;
    patSupported: boolean;
    scmId: string;
    onClose: () => void;
    onClickContinue: () => void;
    handleConnectWithOAuth: () => void;
    handleConnectWithPAT: () => void;
    reset: () => void;
    handleRemoveConnection: () => Promise<unknown>;
};

type AuthenticationMode = "oauth" | "pat";

export const SCMAuthentication: FC<SCMAuthenticationProps> = ({
    authenticationUrl,
    clazz,
    patSupported,
    scmId,
    handleConnectWithOAuth,
    handleConnectWithPAT,
    handleRemoveConnection,
    onClickContinue,
    onClose,
    reset,
    stage,
    repoURL,
    verificationError,
}) => {
    const [mode, setMode] = useState<AuthenticationMode>(authenticationUrl ? "oauth" : "pat");

    if (stage == "required" && mode == "oauth") {
        return (
            <SCMAuthenticationRequiredOAuth
                patSupported={patSupported}
                clazz={clazz}
                scmId={scmId}
                onClick={handleConnectWithOAuth}
                onClickUsePAT={() => {
                    reset();
                    setMode("pat");
                }}
            />
        );
    }

    if (stage == "required" && mode == "pat") {
        return (
            <SCMAuthenticationRequiredPAT
                oauthSupported={!!authenticationUrl}
                repoURL={repoURL}
                clazz={clazz}
                scmId={scmId}
                onSuccess={handleConnectWithPAT}
                onClickUseOAuth={() => {
                    reset();
                    setMode("oauth");
                }}
            />
        );
    }

    if (stage == "pending") {
        return <SCMAuthenticationPending authenticationUrl={authenticationUrl} scmId={scmId} />;
    }

    if (stage == "verifying") {
        return <SCMAuthenticationVerifying scmId={scmId} />;
    }

    if (stage == "error") {
        return (
            <SCMAuthenticationError
                error={verificationError}
                handleRemoveConnection={handleRemoveConnection}
                onClose={onClose}
            />
        );
    }

    if (stage == "successful") {
        return <SCMAuthenticationSuccessful handleConnectionVerified={onClickContinue} scmId={scmId} />;
    }
};

export type SCMAuthenticationModalProps = Omit<
    SCMAuthenticationProps,
    "stage" | "reset" | "handleConnectWithOAuth" | "handleConnectWithPAT" | "handleRemoveConnection"
>;
export const SCMAuthenticationModal: FC<SCMAuthenticationModalProps> = (p) => {
    const props = useSCMAuthentication({
        clazz: p.clazz,
        repoURL: p.repoURL,
        authenticationUrl: p.authenticationUrl,
    });
    const combined: SCMAuthenticationProps = { ...p, ...props };
    return (
        <Dialog open onOpenChange={p.onClose}>
            <DialogContent className="min-h-0 w-[500px] self-center bg-surface-glass p-8">
                <div className="flex flex-col items-center gap-4">
                    <SCMAuthentication {...combined} />
                </div>
            </DialogContent>
        </Dialog>
    );
};

type SCMAuthenticationRequiredPATProps = {
    clazz?: PlainRunnerEnvironmentClass;
    repoURL: string;
    oauthSupported: boolean;
    scmId: string;
    onSuccess: (hostAuthenticationTokenId: string) => void;
    onClickUseOAuth: () => void;
};

const SCMAuthenticationRequiredPAT: FC<SCMAuthenticationRequiredPATProps> = ({
    clazz,
    repoURL,
    oauthSupported,
    scmId,
    onSuccess,
    onClickUseOAuth,
}) => {
    const scmHost = useMemo(() => {
        return new URL(repoURL).host;
    }, [repoURL]);
    const [pat, setPAT] = useState("");

    const createAndVerifyToken = useCreateAndVerifyHostAuthenticationTokenPAT();

    const handleConnect = useCallback(async () => {
        const token = await createAndVerifyToken.mutateAsync({
            pat: pat,
            runnerId: clazz?.runnerId || "",
            scmHost: scmHost,
            repoUrl: repoURL,
        });
        if (token) {
            onSuccess(token.id);
        }
    }, [clazz?.runnerId, createAndVerifyToken, onSuccess, pat, repoURL, scmHost]);

    const loading = createAndVerifyToken.isPending;
    const disabled = loading || !pat;

    const id = useId();
    return (
        <>
            <SCMAuthenticationRequiredHeading clazz={clazz} scmId={scmId} />

            <NumberedCard number={1} heading="Create a Personal Access Token">
                <Text className="max-w-xs text-base">
                    Enable read/write access to repository content.&nbsp;
                    <a
                        href="https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens"
                        target="_blank"
                        rel="noreferrer"
                        className="text-content-link"
                        data-track-label="true"
                    >
                        Learn more
                    </a>
                    .
                </Text>
                <Text className="text-base font-bold">
                    Go to GitHub.com &gt; Settings &gt;{" "}
                    <a
                        href="https://github.com/settings/tokens/new?scopes=repo,workflow,read:user,user:email"
                        target="_blank"
                        rel="noreferrer"
                        className="text-content-link"
                        data-track-label="true"
                    >
                        Developer Settings
                    </a>
                </Text>
            </NumberedCard>

            <NumberedCard number={2} heading="Paste your Personal Access Token">
                <Text className="max-w-xs text-base">Tokens are encrypted. You can edit them in settings later.</Text>
                <div className="flex flex-col gap-2 pt-5">
                    <Label htmlFor={id}>Paste your Personal Access Token</Label>
                    <Input
                        id={id}
                        placeholder="Ex: github_pat_10987654321abcdefghijklmnop"
                        value={pat}
                        onChange={(e) => setPAT(e.target.value)}
                        data-testid="personal-access-token-input"
                    />
                </div>
            </NumberedCard>
            <ErrorMessage
                error={createAndVerifyToken.error}
                className="text-center"
                messages={{
                    [Code.FailedPrecondition]:
                        "Could not find or access the repository. Please verify the repository name and access permissions of the PAT",
                    [Code.NotFound]:
                        "Could not find or access the repository. Please verify the repository name and access permissions of the PAT",
                }}
            />
            <Button
                loading={createAndVerifyToken.isPending}
                disabled={disabled}
                onClick={handleConnect}
                variant="primary"
                className="w-full font-bold"
                data-testid="save-personal-access-token"
                data-track-label="true"
            >
                Connect
            </Button>
            {oauthSupported && (
                <Button
                    variant={"link"}
                    onClick={() => onClickUseOAuth()}
                    className="h-5 p-0 text-base font-normal hover:text-content-link"
                    data-track-label="true"
                >
                    Or use GitHub OAuth
                </Button>
            )}
        </>
    );
};

type SCMAuthenticationRequiredOAuthProps = {
    clazz?: PlainRunnerEnvironmentClass;
    patSupported: boolean;
    scmId: string;
    onClick: () => void;
    onClickUsePAT: () => void;
};

const SCMAuthenticationRequiredOAuth: FC<SCMAuthenticationRequiredOAuthProps> = ({
    onClick,
    onClickUsePAT,
    clazz,
    patSupported,
    scmId,
}) => {
    return (
        <>
            <SCMAuthenticationRequiredHeading clazz={clazz} scmId={scmId} />
            <Button
                onClick={() => onClick()}
                variant={"primary"}
                className="w-full font-bold"
                data-testid="connect-with-github"
                data-track-label="true"
            >
                Connect
            </Button>
            {patSupported && (
                <Button
                    variant={"link"}
                    onClick={() => onClickUsePAT()}
                    className="h-5 border-0 p-0 text-base font-normal hover:text-content-link"
                    data-testid="provide-personal-access-token"
                    data-track-label="true"
                >
                    Or provide a Personal Access Token
                </Button>
            )}
        </>
    );
};

type SCMAuthenticationPendingProps = {
    authenticationUrl?: string;
    scmId: string;
};

const SCMAuthenticationPending: FC<SCMAuthenticationPendingProps> = ({ authenticationUrl, scmId }) => {
    return (
        <>
            <div className="flex flex-col items-center">
                <Heading1>Waiting to connect</Heading1>
                <Text>Please complete the authentication at {getScmProviderName(scmId)}</Text>
            </div>
            <SourceControlProviderIcon scmId={scmId} className="my-2 size-[50px]" />
            <Text className="text-base">
                If the window didn&#x2019;t open,{" "}
                <a
                    href={authenticationUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="text-content-link"
                    data-track-label="true"
                >
                    you can reopen it here
                </a>
                .
            </Text>
        </>
    );
};

const SCMAuthenticationVerifying: FC<{ scmId: string }> = ({ scmId }) => {
    return (
        <>
            <div className="flex flex-col items-center">
                <Heading1>Verifying connection</Heading1>
                <Text>Checking accesses to the repository</Text>
            </div>
            <SourceControlProviderIcon scmId={scmId} size="lg" className="my-2 size-[50px]" />
        </>
    );
};

type ErrorProps = {
    error?: Error;
    handleRemoveConnection: () => Promise<unknown>;
    onClose: () => void;
};

const SCMAuthenticationError: FC<ErrorProps> = ({ error, handleRemoveConnection, onClose }) => {
    const handleClose = useCallback(async () => {
        try {
            await handleRemoveConnection();
        } catch (error) {
            console.error("Failed to remove connection", error);
        }
        onClose();
    }, [handleRemoveConnection, onClose]);
    return (
        <>
            <IconWarning size="lg" className="size-8 text-content-red" />

            <div className="flex flex-col items-center gap-2">
                <Heading1>Repository access failed</Heading1>
                <Text className="p-4 font-mono text-sm">{formatError(error)}</Text>
            </div>

            <Button variant="primary" onClick={handleClose} className="w-full">
                Close
            </Button>
        </>
    );
};

type ConnectionCompletedProps = {
    handleConnectionVerified: () => void;
    scmId: string;
};

const SCMAuthenticationSuccessful: FC<ConnectionCompletedProps> = ({ handleConnectionVerified, scmId }) => {
    return (
        <>
            <div className="flex flex-col items-center">
                <Heading1>{getScmProviderName(scmId)} successfully connected</Heading1>
                <Text>Continue to create the environment</Text>
            </div>

            <IconCheckFill size="lg" className="size-14 text-content-green" />

            <Button variant="primary" onClick={handleConnectionVerified} className="w-full" data-track-label="true">
                Continue
            </Button>
        </>
    );
};

const SCMAuthenticationRequiredHeading: FC<{ clazz?: PlainRunnerEnvironmentClass; scmId?: string }> = ({
    clazz,
    scmId,
}) => {
    const { data: runner } = useRunner(clazz?.runnerId);
    return (
        <>
            <div className="flex flex-col items-center gap-2 pt-4">
                <Heading1>Connect {getScmProviderName(scmId)}</Heading1>
                <Text className="text-center text-lg text-content-secondary">
                    All authentication happens on your infrastructure, ensuring maximum security and control.
                </Text>
            </div>
            <div className="flex flex-col items-center gap-2">
                {clazz && (
                    <>
                        <SourceControlProviderIcon scmId={scmId} className="size-[50px]" />
                        <div className="flex flex-row items-center justify-between">
                            <div className="flex flex-col items-center">
                                <div className="flex gap-1 rounded-md px-4 py-1">
                                    <span className="text-base font-bold text-content-primary group-data-[disabled]:text-content-tertiary">
                                        {clazz?.displayName || "Unknown class"}
                                    </span>
                                    <span className="text-base text-content-tertiary">
                                        {runner?.name || "Unknown runner"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

const NumberedCard: FC<PropsWithChildren & { number: number; heading: string }> = ({ number, heading, children }) => {
    return (
        <Card className="flex flex-row justify-items-start gap-3 bg-surface-secondary">
            <div>{number}</div>
            <div className="flex flex-col gap-1">
                <Heading2>{heading}</Heading2>
                {children}
            </div>
        </Card>
    );
};
