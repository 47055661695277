import { IconClose } from "@/assets/icons/geist/IconClose";
import * as Popover from "@radix-ui/react-popover";
import { cloneElement, useState, type FC, type ReactElement } from "react";

export const WithPopover: FC<
    { button: ReactElement; anchor?: React.RefObject<HTMLElement> } & { children: ReactElement }
> = ({ button, children, anchor }) => {
    const [open, setOpen] = useState(false);

    return (
        <Popover.Root modal={true} open={open} onOpenChange={setOpen}>
            <Popover.Anchor asChild virtualRef={anchor} />
            <Popover.Trigger asChild type={undefined}>
                {button}
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    side="bottom"
                    sideOffset={10}
                    className="z-10 rounded-xl bg-surface-glass shadow-xl backdrop-blur-sm data-[state=open]:data-[side=top]:animate-in data-[state=open]:data-[side=top]:fade-in data-[state=open]:data-[side=top]:zoom-in data-[state=open]:data-[side=top]:slide-in-from-bottom"
                >
                    <Popover.Close
                        className="fixed right-6 top-6 inline-flex h-6 w-6 cursor-pointer items-center justify-center outline-none"
                        aria-label="Close"
                    >
                        <IconClose size="sm" />
                    </Popover.Close>
                    {cloneElement(children, { onClose: () => setOpen(false) })}
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};
