// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file gitpod/v1/organization.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateOrganizationInviteRequest, CreateOrganizationInviteResponse, CreateOrganizationRequest, CreateOrganizationResponse, CreateSSOConfigurationRequest, CreateSSOConfigurationResponse, DeleteOrganizationRequest, DeleteOrganizationResponse, GetOrganizationInviteRequest, GetOrganizationInviteResponse, GetOrganizationInviteSummaryRequest, GetOrganizationInviteSummaryResponse, GetOrganizationRequest, GetOrganizationResponse, GetSSOConfigurationRequest, GetSSOConfigurationResponse, JoinOrganizationRequest, JoinOrganizationResponse, LeaveOrganizationRequest, LeaveOrganizationResponse, ListMembersRequest, ListMembersResponse, ListOrganizationsRequest, ListOrganizationsResponse, ListSSOConfigurationsRequest, ListSSOConfigurationsResponse, SetRoleRequest, SetRoleResponse, UpdateOrganizationRequest, UpdateOrganizationResponse, UpdateSSOConfigurationRequest, UpdateSSOConfigurationResponse } from "./organization_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service gitpod.v1.OrganizationService
 */
export const OrganizationService = {
  typeName: "gitpod.v1.OrganizationService",
  methods: {
    /**
     * CreateOrganization creates a new Organization.
     *
     * @generated from rpc gitpod.v1.OrganizationService.CreateOrganization
     */
    createOrganization: {
      name: "CreateOrganization",
      I: CreateOrganizationRequest,
      O: CreateOrganizationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * JoinOrganization lets accounts join an Organization.
     *
     * @generated from rpc gitpod.v1.OrganizationService.JoinOrganization
     */
    joinOrganization: {
      name: "JoinOrganization",
      I: JoinOrganizationRequest,
      O: JoinOrganizationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetOrganization retrieves a single Organization.
     *
     * @generated from rpc gitpod.v1.OrganizationService.GetOrganization
     */
    getOrganization: {
      name: "GetOrganization",
      I: GetOrganizationRequest,
      O: GetOrganizationResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * UpdateOrganization updates the properties of an Organization.
     *
     * @generated from rpc gitpod.v1.OrganizationService.UpdateOrganization
     */
    updateOrganization: {
      name: "UpdateOrganization",
      I: UpdateOrganizationRequest,
      O: UpdateOrganizationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListOrganizations lists all organization the caller has access to.
     *
     * @generated from rpc gitpod.v1.OrganizationService.ListOrganizations
     */
    listOrganizations: {
      name: "ListOrganizations",
      I: ListOrganizationsRequest,
      O: ListOrganizationsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * DeleteOrganization deletes the specified organization.
     *
     * @generated from rpc gitpod.v1.OrganizationService.DeleteOrganization
     */
    deleteOrganization: {
      name: "DeleteOrganization",
      I: DeleteOrganizationRequest,
      O: DeleteOrganizationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListMembers lists all members of the specified organization.
     *
     * @generated from rpc gitpod.v1.OrganizationService.ListMembers
     */
    listMembers: {
      name: "ListMembers",
      I: ListMembersRequest,
      O: ListMembersResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc gitpod.v1.OrganizationService.SetRole
     */
    setRole: {
      name: "SetRole",
      I: SetRoleRequest,
      O: SetRoleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateOrganizationInvite creates an invite for the organization.
     * Any existing OrganizationInvites are invalidated and can no longer be used.
     *
     * @generated from rpc gitpod.v1.OrganizationService.CreateOrganizationInvite
     */
    createOrganizationInvite: {
      name: "CreateOrganizationInvite",
      I: CreateOrganizationInviteRequest,
      O: CreateOrganizationInviteResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetOrganizationInvite retrieves invite for the organization.
     * If no invite exists, a new one is created.
     *
     * @generated from rpc gitpod.v1.OrganizationService.GetOrganizationInvite
     */
    getOrganizationInvite: {
      name: "GetOrganizationInvite",
      I: GetOrganizationInviteRequest,
      O: GetOrganizationInviteResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetOrganizationInviteSummary retrieves a summary of the organization based on an Invite ID.
     * Used to discover which organization an invite is for.
     *
     * @generated from rpc gitpod.v1.OrganizationService.GetOrganizationInviteSummary
     */
    getOrganizationInviteSummary: {
      name: "GetOrganizationInviteSummary",
      I: GetOrganizationInviteSummaryRequest,
      O: GetOrganizationInviteSummaryResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * LeaveOrganization lets the passed user leave an Organization.
     *
     * @generated from rpc gitpod.v1.OrganizationService.LeaveOrganization
     */
    leaveOrganization: {
      name: "LeaveOrganization",
      I: LeaveOrganizationRequest,
      O: LeaveOrganizationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateSSOConfiguration creates a new SSO configuration for the organization.
     *
     * @generated from rpc gitpod.v1.OrganizationService.CreateSSOConfiguration
     */
    createSSOConfiguration: {
      name: "CreateSSOConfiguration",
      I: CreateSSOConfigurationRequest,
      O: CreateSSOConfigurationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetSSOConfiguration returns an SSO configuration.
     *
     * @generated from rpc gitpod.v1.OrganizationService.GetSSOConfiguration
     */
    getSSOConfiguration: {
      name: "GetSSOConfiguration",
      I: GetSSOConfigurationRequest,
      O: GetSSOConfigurationResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * ListSSOConfigurations lists all SSO configurations matching provided filters.
     *
     * @generated from rpc gitpod.v1.OrganizationService.ListSSOConfigurations
     */
    listSSOConfigurations: {
      name: "ListSSOConfigurations",
      I: ListSSOConfigurationsRequest,
      O: ListSSOConfigurationsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * UpdateSSOConfiguration updates the SSO configuration for the organization.
     *
     * @generated from rpc gitpod.v1.OrganizationService.UpdateSSOConfiguration
     */
    updateSSOConfiguration: {
      name: "UpdateSSOConfiguration",
      I: UpdateSSOConfigurationRequest,
      O: UpdateSSOConfigurationResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

