import type { Size } from "@/assets/icons/geist/Size";
import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC, PropsWithChildren } from "react";

type SkeletonProps = {
    ready: boolean;
    failed?: boolean;
    hideOnFailed?: boolean;
    animate?: boolean;
};

export const SkeletonText: FC<{ size: Size } & SkeletonProps & PropsWithClassName & PropsWithChildren> = ({
    className,
    size,
    ready,
    failed,
    animate,
    hideOnFailed,
    children,
}) => {
    // We're mathcing the line-height of the text but splitting it between height and margin
    // so it feels less clunky
    let height: string;
    switch (size) {
        case "sm":
            height = "h-3";
            break;
        case "base":
            height = "h-4";
            break;
        case "lg":
            height = "h-5";
            break;
    }
    return (
        <SkeletonBlock
            animate={animate}
            ready={ready}
            failed={failed}
            hideOnFailed={hideOnFailed}
            className={cn("my-[2px] w-8", height, className)}
        >
            {children}
        </SkeletonBlock>
    );
};

export const SkeletonBlock: FC<SkeletonProps & PropsWithClassName & PropsWithChildren> = ({
    failed,
    ready,
    animate,
    children,
    className,
    hideOnFailed,
}) => {
    if (ready) {
        return children;
    }
    return (
        <div
            className={cn(
                "block rounded-md bg-surface-tertiary",
                !failed && (animate ?? true) && "animate-pulse",
                // Using opacity instead of hidden to keep the layout
                failed && hideOnFailed && "opacity-0",
                className,
            )}
        />
    );
};
