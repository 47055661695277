import { IconChevronDown } from "@/assets/icons/geist/IconChevronDown";
import { IconCommit } from "@/assets/icons/geist/IconCommit";
import { IconFile } from "@/assets/icons/geist/IconFile";
import { Button } from "@/components/flexkit/Button";
import { cn } from "@/components/podkit/lib/cn";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/podkit/popover/Popover";
import { Text } from "@/components/podkit/typography/Text";
import { type PlainEnvironment } from "@/queries/environment-queries";
import { type PlainMessage } from "@bufbuild/protobuf";
import type { FileChange } from "gitpod-next-api/gitpod/v1/environment_pb";
import { FileChange_ChangeType } from "gitpod-next-api/gitpod/v1/environment_pb";
import { type FC, type PropsWithChildren } from "react";

export const GitStatusPopOver: FC<{ environment: PlainEnvironment }> = ({ environment }) => {
    const unpushedTotal = environment.status?.content?.git?.totalUnpushedCommits ?? 0;
    const changedFilesTotal = environment.status?.content?.git?.totalChangedFiles ?? 0;
    const disabled = unpushedTotal === 0 && changedFilesTotal === 0;
    let label = "No changes";
    let dirty = false;
    if (changedFilesTotal > 0) {
        label = "Uncommitted changes";
        dirty = true;
    }
    if (unpushedTotal > 0) {
        label = `${unpushedTotal} ${unpushedTotal > 1 ? "commits" : "commit"} ahead of remote`;
        dirty = true;
    }
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button variant="link" className="group p-0 font-normal" disabled={disabled}>
                    {label}
                    <IconChevronDown
                        size="sm"
                        className={cn(
                            "size-3 text-content-secondary",
                            dirty ? "group-hover:text-content-primary" : "text-content-tertiary",
                        )}
                    />
                </Button>
            </PopoverTrigger>
            <PopoverContent align="end" className="z-10 flex w-[380px] flex-col gap-4 p-5 focus-visible:ring-0">
                <div>
                    <div className="font-bold">Git status</div>
                    <p className="text-base text-content-secondary">{label}</p>
                </div>
                <UnpushedCommits environment={environment} />
                <ChangedFiles environment={environment} />
            </PopoverContent>
        </Popover>
    );
};

const ChangedFiles: FC<{ environment?: PlainEnvironment }> = ({ environment }) => {
    return (
        <GitDetailsSection
            title="Uncomitted files"
            count={environment?.status?.content?.git?.totalChangedFiles}
            data-testid="environment-details-changed-files"
        >
            {environment?.status?.content?.git?.changedFiles.map((file) => (
                <GitFileChange key={file.path} file={file} />
            ))}
        </GitDetailsSection>
    );
};

const UnpushedCommits: FC<{ environment?: PlainEnvironment }> = ({ environment }) => {
    return (
        <GitDetailsSection
            title="Unpushed commits"
            count={environment?.status?.content?.git?.totalUnpushedCommits}
            data-testid="environment-details-unpushed-commits"
        >
            {environment?.status?.content?.git?.unpushedCommits.map((commit) => (
                <GitCommit key={commit} commit={commit} />
            ))}
        </GitDetailsSection>
    );
};

const GitDetailsSection: FC<
    { title: string; count: number | undefined; "data-testid": string } & PropsWithChildren
> = ({ title, count, children, ...props }) => {
    if (!count) {
        return null;
    }
    return (
        <div className="flex flex-col" data-testid={props["data-testid"]}>
            <div className="flex justify-between">
                <Text className="mb-1 text-base">{title}</Text>
                <span className="text-base font-bold text-content-secondary">{count}</span>
            </div>
            <div className="flex flex-col gap-1">{children}</div>
        </div>
    );
};

export const GitCommit: FC<{ commit: string }> = ({ commit }) => {
    return (
        <div className="flex items-center gap-2">
            <IconCommit className="size-4 shrink-0" size="sm" />
            <span className="truncate text-sm text-content-secondary" title={commit}>
                {commit}
            </span>
        </div>
    );
};

export const GitFileChange: FC<{ file: PlainMessage<FileChange> }> = ({ file }) => {
    const parts = file.path.split("/");
    const path = parts.slice(0, parts.length - 1);
    const filename = parts[parts.length - 1];
    return (
        <div className="flex items-center gap-2">
            <IconFile className="size-4 shrink-0" size="sm" />
            <span className="max-w-[70%] shrink-0 truncate text-sm font-bold" title={filename}>
                {filename}
            </span>
            <span className="shrink truncate text-sm text-content-secondary" title={file.path}>
                {path.join("/")}
            </span>
            <GitChangeType changeType={file.changeType} />
        </div>
    );
};

/**
 * See https://git-scm.com/docs/git-status to reference the the letters
 */
const GitChangeType: FC<{ changeType: FileChange_ChangeType }> = ({ changeType }) => {
    let letter: React.ReactNode;
    switch (changeType) {
        case FileChange_ChangeType.UNSPECIFIED:
            letter = <span />;
            break;
        case FileChange_ChangeType.ADDED:
            letter = <span className="text-content-positive">A</span>;
            break;
        case FileChange_ChangeType.MODIFIED:
            letter = <span className="text-content-yield">M</span>;
            break;
        case FileChange_ChangeType.DELETED:
            letter = <span className="text-content-negative">D</span>;
            break;
        case FileChange_ChangeType.RENAMED:
            letter = <span className="text-content-positive">R</span>;
            break;
        case FileChange_ChangeType.COPIED:
            letter = <span className="text-content-positive">C</span>;
            break;
        case FileChange_ChangeType.UPDATED_BUT_UNMERGED:
            letter = <span className="text-content-negative">U</span>;
            break;
        case FileChange_ChangeType.UNTRACKED:
            letter = <span className="text-content-negative">?</span>;
            break;
    }

    return <div className="ml-auto h-5 w-9 text-right text-base">{letter}</div>;
};
