import { useToast } from "@/components/podkit/toasts/use-toast";
import { useEnvironment, useStartEnvironment, useStopEnvironment } from "@/queries/environment-queries";
import {
    environmentHasReachedStablePhase,
    hasFailureMessageFromSameSession,
    showStart,
    showStop,
    type EffectiveState,
} from "@/routes/environments/phase";
import { formatError } from "@/utils/errors";
import * as Switch from "@radix-ui/react-switch";
import { useCallback, useEffect, useState, type FC } from "react";
import { cn, type PropsWithClassName } from "../podkit/lib/cn";
import { EnvironmentPhase } from "gitpod-next-api/gitpod/v1/environment_pb";
import { Heading1 } from "@/components/podkit/typography/Headings";

type State = "running" | "starting" | "stopped" | "stopping" | "deleting" | "deleted";

export const EnvironmentToggle: FC<{ environmentId: string; effectiveState?: EffectiveState }> = ({
    environmentId,
    effectiveState,
}) => {
    const { toast } = useToast();
    const { data: environment } = useEnvironment(environmentId);
    const startEnvironment = useStartEnvironment();
    const stopEnvironment = useStopEnvironment();

    const [state, setState] = useState<State>("stopped");

    const hasFailureMessage = environment ? hasFailureMessageFromSameSession(environment) : false;
    const isGreen = state === "running" || state === "starting";
    const isTransitory =
        startEnvironment.isPending ||
        stopEnvironment.isPending ||
        state === "starting" ||
        state === "stopping" ||
        state === "deleting";

    useEffect(() => {
        if (!environment) {
            return;
        }
        const hasReachedStablePhase = environmentHasReachedStablePhase(environment);
        if (environment.spec?.desiredPhase === EnvironmentPhase.DELETED) {
            setState(hasReachedStablePhase ? "deleted" : "deleting");
            return;
        }
        if (showStart(environment)) {
            setState(hasReachedStablePhase ? "stopped" : "stopping");
            return;
        }
        if (showStop(environment)) {
            setState(hasReachedStablePhase ? "running" : "starting");
            return;
        }
    }, [environment?.status?.phase, environment?.spec?.desiredPhase, environment]);

    const handleClick = useCallback(async () => {
        if (state === "stopped") {
            try {
                await startEnvironment.mutateAsync(environmentId);
            } catch (error) {
                toast({
                    title: "Failed to start environment",
                    description: formatError(error),
                });
            }
        } else if (state === "running") {
            try {
                await stopEnvironment.mutateAsync(environmentId);
            } catch (error) {
                toast({
                    title: "Failed to stop environment",
                    description: formatError(error),
                });
            }
        }
    }, [environmentId, startEnvironment, state, stopEnvironment, toast]);

    const disabled = isTransitory || state === "deleted";

    return (
        <div className="inline-flex h-12 items-center justify-end gap-2">
            <div className="flex w-[40px] justify-center">
                <Switch.Root
                    checked={isGreen}
                    disabled={disabled}
                    onCheckedChange={handleClick}
                    className={cn(
                        "h-[25px] w-[40px] cursor-pointer rounded-full bg-surface-04",
                        "transition-[width] duration-300",
                        "disabled:cursor-default",
                        { "w-[25px]": isTransitory },
                        { "data-[state=checked]:bg-content-green": !isTransitory },
                        { "bg-content-red data-[state=checked]:bg-content-red": hasFailureMessage && !isTransitory },
                    )}
                    id={"toggle-env-" + environmentId}
                >
                    <Switch.Thumb
                        className={cn(
                            "flex size-[25px] items-center justify-center",
                            "transition-transform duration-300",
                            {
                                "data-[state=checked]:translate-x-[15px]": !isTransitory,
                            },
                        )}
                    >
                        <ThumbSVG animating={isTransitory} />
                    </Switch.Thumb>
                </Switch.Root>
            </div>
            <label
                className={cn(
                    "grow cursor-pointer select-none justify-start text-base font-normal leading-tight text-content-primary",
                    { "cursor-default": disabled },
                )}
                htmlFor={"toggle-env-" + environmentId}
                translate="no"
            >
                <StatusText state={effectiveState} />
            </label>
        </div>
    );
};

const ThumbSVG: FC<PropsWithClassName<{ animating: boolean }>> = ({ className, animating }) => {
    /**
     * Implementation notes:
     * The basic idea is to have a circle with a partial stroke and have it spin using CSS. The stroke is transparent
     * when the thumb is not "loading".
     *
     * You can't define if a border should be drawn "outside" of a circle, so to have full control of the border we're
     * using two circle: one for the thumb and one for the border. The border-circle is transparent and uses a stroke with
     * a stroke-dasharray to achieve the "partial border" effect.
     */
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={cn({ "animate-spin text-content-yield duration-1000": animating }, className)}
        >
            <g filter="url(#filter0_d_2076_8655)">
                <circle cx="12.5" cy="12.5" r={10} fill="rgb(var(--surface-glass))" />
            </g>
            <circle
                cx="12.5"
                cy="12.5"
                r={11.5}
                fill="none"
                stroke={animating ? "currentColor" : "transparent"}
                strokeWidth={2}
                // 18 is the length of the stroke
                // 76 is the circumference of the circle to ensure we only render "one" stroke
                strokeDasharray={"18 76"}
            />
            {/* Drop-shadow effect copied from Figma */}
            <defs>
                <filter
                    id="filter0_d_2076_8655"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2076_8655" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2076_8655" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

const StatusText: FC<{ state?: EffectiveState }> = ({ state }) => {
    if (!state) {
        return null;
    }

    let text = "Unknown";

    switch (state.state) {
        case EnvironmentPhase.RUNNING:
            text = "Running";
            break;
        case EnvironmentPhase.CREATING:
        case EnvironmentPhase.STARTING:
            text = "Starting";
            break;
        case EnvironmentPhase.UPDATING:
            text = "Updating";
            break;
        case EnvironmentPhase.STOPPING:
            text = "Stopping";
            if (state.timeout) {
                text = `Auto-stopping`;
            }
            break;
        case EnvironmentPhase.STOPPED:
            text = "Stopped";
            if (state.timeout) {
                text = `Auto-stopped`;
            }
            break;
        case EnvironmentPhase.DELETING:
            text = "Deleting";
            break;
        case EnvironmentPhase.DELETED:
            text = "Deleted";
            break;
    }

    if (state.state === "OFFLINE") {
        text = "Runner is offline";
    }

    return <Heading1 className="text-xl">{text}</Heading1>;
};
