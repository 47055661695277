import { SkeletonText } from "@/components/podkit/loading/Skeleton";
import { Heading1 } from "@/components/podkit/typography/Headings";
import { useEnvironment } from "@/queries/environment-queries";
import { useProject } from "@/queries/project-queries";
import { GitBranch, Repository } from "@/routes/environments/details/EnvironmentDetailsHeader";
import { GitStatusPopOver } from "@/routes/environments/details/GitStatusPopOver";
import { EnvironmentStatus_ContentPhase } from "gitpod-next-api/gitpod/v1/environment_pb";
import { useMemo, type FC } from "react";
import { useParams } from "react-router-dom";
import { ActionBar } from "./ActionBar";

export const EnvironmentDetailsHeader: FC<{ showActionBar: boolean }> = ({ showActionBar }) => {
    const { environmentId } = useParams();
    const { data: environment, error: environmentError } = useEnvironment(environmentId);
    const { data: project } = useProject(environment?.metadata?.projectId);

    const isBasedOnProject = environment?.metadata?.projectId;
    const ready: boolean = (!!environment && !!project) || (!!environment && !environment?.metadata?.projectId);

    const gitFailed =
        Boolean(environmentError) || environment?.status?.content?.phase === EnvironmentStatus_ContentPhase.FAILED;

    const branch = useMemo(() => {
        if (!environment?.status?.content?.git?.branch && project) {
            return "main";
        }
        return environment?.status?.content?.git?.branch;
    }, [environment, project]);

    return (
        <div className="flex flex-wrap items-center justify-between gap-1">
            <div className="flex flex-col" translate="no">
                <SkeletonText ready={ready} className="w-52" size={"lg"}>
                    <Heading1 className="flex flex-row align-middle text-content-primary">
                        {isBasedOnProject ? (
                            <>{project?.metadata?.name}</>
                        ) : (
                            <Repository environment={environment} failed={gitFailed} showRepoUrlLink={true} />
                        )}
                    </Heading1>
                </SkeletonText>
                <div className="flex flex-row items-center">
                    <GitBranch branch={branch} failed={gitFailed} />
                    {environment && (
                        <>
                            <span className="text-content-secondary">{"\u00A0\u00A0•\u00A0\u00A0"}</span>
                            <GitStatusPopOver environment={environment} />
                        </>
                    )}
                </div>
            </div>
            {environmentId && showActionBar && <ActionBar environmentId={environmentId} />}
        </div>
    );
};
