import { EnvironmentDetailsHeader } from "@/components/environments/EnvironmentDetailsHeader";
import { EnvironmentOnboarding } from "@/components/environments/EnvironmentOnboarding";
import { EnvironmentPorts } from "@/components/environments/EnvironmentPorts";
import { EnvironmentStartSqeuence } from "@/components/environments/EnvironmentStartSequence";
import { useEnvironment } from "@/queries/environment-queries";
import { EnvironmentAutomations } from "@/routes/environments/details/EnvironmentAutomations";
import { type FC, type PropsWithChildren } from "react";

export const EnvirontmentDetails: FC<{ environmentId: string }> = ({ environmentId }) => {
    return (
        <div className="flex size-full flex-col gap-6">
            <EnvironmentDetailsHeader showActionBar={true} />
            <EnvironmentStartSqeuence environmentId={environmentId} />
            <WhenReady environmentId={environmentId}>
                <EnvironmentOnboarding environmentId={environmentId} />
                <EnvironmentPorts environmentId={environmentId} />
                <EnvironmentAutomations environmentId={environmentId} />
            </WhenReady>
        </div>
    );
};

const WhenReady: FC<PropsWithChildren & { environmentId: string }> = ({ children, environmentId }) => {
    const { data: environment, isPending } = useEnvironment(environmentId);
    const ready = !isPending && !!environment;

    if (!ready) {
        return null;
    }

    return children;
};
