import { StepState } from "@/components/environments/environment-start-steps-state";
import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import { type FC } from "react";

export const StartSequenceStepIcon: FC<{ state: StepState } & PropsWithClassName> = ({ state, className }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle
                xmlns="http://www.w3.org/2000/svg"
                cx="12"
                cy="12"
                r="3"
                fill="currentColor"
                className={cn("transition-colors duration-300 [transition-property:r]", {
                    "text-content-primary [r:3]": state === StepState.Waiting,
                    "text-content-yield [r:3]": state === StepState.Running,
                    "text-content-green [r:8]": state === StepState.Success,
                    "[r:0]": state === StepState.Empty || state === StepState.Failure,
                })}
            />
            <circle
                xmlns="http://www.w3.org/2000/svg"
                cx="12"
                cy="12"
                r="10"
                fill="currentColor"
                opacity={0.3}
                className={cn("transition-colors duration-150", {
                    "text-content-primary": state === StepState.Waiting || state === StepState.Empty,
                    "duration-2000 animate-circlePulse text-content-yield": state === StepState.Running,
                    "text-content-green": state === StepState.Success,
                    "[r:0]": state === StepState.Failure,
                })}
            />
            {state === StepState.Success && (
                <path
                    d="M8 12.5L11 15L16 9"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    strokeDasharray={12}
                    strokeDashoffset={12}
                    className={"animate-draw delay-500 duration-150"}
                />
            )}
            {state === StepState.Failure && (
                <path
                    d="M12 9.36996V12.3154M12 14.3404V14.3331M10.1211 6.14899L5.4324 13.7308C4.52227 15.2025 5.58084 17.1018 7.31124 17.1018H16.6887C18.4191 17.1018 19.4777 15.2025 18.5675 13.7308L13.8788 6.14898C13.0154 4.75275 10.9846 4.75275 10.1211 6.14899ZM12.1841 14.3404C12.1841 14.4421 12.1017 14.5245 12 14.5245C11.8983 14.5245 11.8159 14.4421 11.8159 14.3404C11.8159 14.2387 11.8983 14.1564 12 14.1564C12.1017 14.1564 12.1841 14.2387 12.1841 14.3404Z"
                    stroke="#E5484D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
            )}
        </svg>
    );
};
