import type { ReactNode, PropsWithChildren } from "react";
import { InputFieldHint } from "./InputFieldHint";
import { cn } from "@/components/podkit/lib/cn";

type Props = {
    label?: ReactNode;
    id?: string;
    hint?: ReactNode;
    error?: ReactNode;
    className?: string;
    disabled?: boolean;
    "data-testid"?: string;
};
export const InputField = ({
    label,
    id,
    hint,
    error,
    className,
    children,
    disabled = false,
    "data-testid": testId,
}: PropsWithChildren<Props>) => {
    return (
        <div className={cn("flex flex-col space-y-2", className)} data-testid={testId}>
            {label && (
                <label
                    className={cn(
                        "text-md font-bold",
                        disabled ? "text-gray-400 dark:text-gray-400" : error ? "text-red-600 dark:text-red-400" : "",
                    )}
                    htmlFor={id}
                >
                    {label}
                </label>
            )}
            {children}
            {error && <span className="text-sm text-red-500">{error}</span>}
            {hint && <InputFieldHint>{hint}</InputFieldHint>}
        </div>
    );
};
