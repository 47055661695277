import { IconSpinner } from "@/assets/icons/geist/IconSpinner";
import type { Size } from "@/assets/icons/geist/Size";
import { cn } from "@/components/podkit/lib/cn";
import { Slot, Slottable } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import {
    cloneElement,
    forwardRef,
    Fragment,
    isValidElement,
    type ButtonHTMLAttributes,
    type ReactElement,
    type ReactNode,
} from "react";

const ButtonVariants = cva(
    cn(
        "select-none",
        "inline-flex items-center gap-1 font-medium justify-center whitespace-nowrap transition-colors ring-inset",
        "border-0.5 border-border-base",
        "disabled:bg-surface-03 disabled:border-opacity-0 disabled:text-content-tertiary disabled:pointer-events-none disabled:shadow-none",
    ),
    {
        variants: {
            variant: {
                primary: "shadow-sm bg-content-primary text-content-invert hover:bg-content-secondary",
                secondary: "shadow-sm bg-surface-glass hover:bg-surface-03",
                brand: "shadow-sm text-content-invert bg-content-orange hover:bg-content-orange/80",
                destructive: "shadow-sm text-content-invert bg-content-red hover:bg-content-red/80",
                ghost: "disabled:bg-transparent disabled:text-content-secondary bg-transparent hover:bg-surface-03",
                link: "disabled:bg-transparent disabled:text-content-secondary border-0 text-content-secondary",
            },
            size: {
                lg: "px-4 py-[10px] rounded-xl text-base font-medium",
                md: "px-4 pt-[5.5px] pb-[6.5px] rounded-xl text-base h-[32px]",
                sm: "py-1 px-2 rounded-lg text-sm",
            },
        },
        defaultVariants: {
            variant: "primary",
            size: "md",
        },
    },
);

export type ButtonVariant = VariantProps<typeof ButtonVariants>["variant"];

export type ButtonProps = {
    asChild?: boolean;
    loading?: boolean;
    LeadingIcon?: React.ComponentType<{ size: Size }>;
    _disableTranslateWrapping?: boolean;
    "data-track-label"?: string;
} & ButtonHTMLAttributes<HTMLButtonElement> &
    VariantProps<typeof ButtonVariants>;

/**
 * Notes for converting from Figma component properties
 * - iconOnly is achieved by not passing in children and providing a LeadingIcon
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, size, asChild = false, LeadingIcon, loading, disabled, children, ...props }, ref) => {
        const Comp = asChild ? Slot : "button";
        let iconOnlyOverrides = "";
        if (!children) {
            switch (size) {
                case "md":
                    iconOnlyOverrides = "pt-[6.5px] pb-[5.5px] px-[7.5px]";
                    break;
                case "sm":
                    iconOnlyOverrides = "p-1";
                    break;
            }
        }
        let iconSize: Size = "base";
        switch (size) {
            case "sm":
                iconSize = "sm";
                break;
            case "md":
                iconSize = "base";
                break;
            case "lg":
                iconSize = "lg";
                break;
        }

        let leftElement;
        if (loading) {
            leftElement = <IconSpinner className="animate-spin" size={iconSize} />;
        } else if (LeadingIcon) {
            leftElement = <LeadingIcon size={iconSize} />;
        }

        let content: ReactNode = children;
        if (!props._disableTranslateWrapping) {
            // Wrap text content in a span to prevent G translate issues
            content = Array.isArray(children)
                ? children.map((child: ReactNode, i: number) => wrapInSpan(child, `wrapped-child-${i}`))
                : wrapInSpan(content, "wrapped-child");

            if (asChild && isValidElement(children)) {
                return (
                    <Slot
                        className={cn(ButtonVariants({ variant, size }), iconOnlyOverrides, className)}
                        ref={ref}
                        data-track-label={props["data-track-label"]}
                        {...props}
                    >
                        {cloneElement(
                            children,
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                            children.props,
                            <>
                                {leftElement}
                                <span className="flex items-center gap-1">{children.props.children}</span>
                            </>,
                        )}
                    </Slot>
                );
            }
        }

        return (
            <Comp
                className={cn(ButtonVariants({ variant, size }), iconOnlyOverrides, className)}
                ref={ref}
                disabled={disabled || loading}
                data-track-label={props["data-track-label"]}
                {...props}
            >
                {leftElement}
                <Slottable>{content}</Slottable>
            </Comp>
        );
    },
);
Button.displayName = "Button";

function wrapInSpan(node: ReactNode, key: string): ReactNode {
    if (typeof node === "string") {
        return <span key={key}>{node}</span>;
    }
    if (isValidElement<ReactElement>(node) && node.type === Fragment) {
        return <span key={key}>{node}</span>;
    }
    return node;
}
