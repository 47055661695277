import { useGetAccount } from "@/queries/account-queries";
import { useContext, useEffect } from "react";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { SegmentContext } from "@/contexts/SegmentContext";
import { BuildVersion } from "@/utils/version";
import type { MuxPlayerRefAttributes } from "@mux/mux-player-react";

export const useSegment = (): SegmentContext => useContext(SegmentContext);

export const useSegmentIdentify = () => {
    const segment = useSegment();
    const { data: account } = useGetAccount();

    useEffect(() => {
        if (!segment) {
            console.debug("segment not available");
            return;
        }

        if (!account) {
            console.debug("Account not available");
            return;
        }
        segment
            .identify(account.id, {
                name: account.name,
                email: account.email,
                avatar: account.avatarUrl,
            })
            .catch(console.error);
    }, [account, segment]);
};

export const useResetSegment = () => {
    const segment = useSegment();

    return () => {
        if (!segment) {
            console.debug("segment not available");
            return;
        }

        segment.reset().catch(console.error);
    };
};

function isMuxPlayerElement(element: Element): element is MuxPlayerRefAttributes {
    return element.tagName.toLowerCase() === "mux-player";
}

type NavigatorUA = {
    readonly userAgentData?: NavigatorUAData;
} & Navigator;
type NavigatorUAData = {
    getHighEntropyValues(hints: string[]): Promise<UADataValues>;
};
type UADataValues = {
    readonly architecture?: string;
};
function isNavigatorUA(n: Navigator): n is NavigatorUA {
    return "userAgentData" in n;
}

let architecture: string | undefined;
void (async () => {
    try {
        if (isNavigatorUA(navigator) && navigator.userAgentData?.getHighEntropyValues) {
            const userData = await navigator.userAgentData.getHighEntropyValues(["architecture"]);
            if (userData.architecture) {
                architecture = userData.architecture;
            }
        }
    } catch (error) {
        console.error("Error fetching architecture:", error);
    }
})();

export const useSegmentTrack = () => {
    const segment = useSegment();
    const { data: user } = useAuthenticatedUser();
    const { data: account } = useGetAccount();

    return (eventName: string, properties?: Record<string, unknown>) => {
        if (!segment) {
            console.debug("segment not available");
            return;
        }

        const props = properties || {};
        if (user) {
            props.user_id = user.id;
            props.auth_principal = "user";
            props.auth_id = user.id;
            props.account_id = account?.id;
            props.organization_id = user.organizationId;
        }
        if (window.isWebview) {
            props.client = "vscode";
        } else if (window.ipcRenderer) {
            props.client = "desktop";
        } else {
            props.client = "web";
        }
        props.dashboardVersion = BuildVersion;
        if (architecture) {
            props.architecture = architecture;
        }

        segment.track(eventName, props).catch(console.error);
    };
};

export const useButtonOrAnchorTracking = () => {
    const track = useSegmentTrack();

    useEffect(() => {
        const trackButtonOrAnchor = (
            target: HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | MuxPlayerRefAttributes,
        ) => {
            const trackingMsg: ButtonOrAnchorTrackingProps = {
                path: window.location.pathname,
                label: target.ariaLabel || target.textContent || undefined,
            };
            const trackLabel = target.dataset.trackLabel;
            if (!target.dataset.trackLabel) {
                trackingMsg.label = "not-tracked";
            } else if (trackLabel !== "true") {
                trackingMsg.label = trackLabel;
            }

            if (target instanceof HTMLButtonElement || target instanceof HTMLDivElement) {
                //retrieve href if parent is an anchor element
                if (target.parentElement instanceof HTMLAnchorElement) {
                    const anchor = target.parentElement;
                    trackingMsg.destination = anchor.href;
                }
            }

            if (target instanceof HTMLAnchorElement) {
                const anchor = target;
                trackingMsg.destination = anchor.href;
            }

            track("dashboard_clicked", trackingMsg);
        };

        const handleButtonOrAnchorTracking = (props: MouseEvent) => {
            const root = document.getElementById("root");
            let curr = props.target as HTMLElement;

            while (!(curr instanceof Document) && curr !== root) {
                if (
                    curr instanceof HTMLButtonElement ||
                    curr instanceof HTMLAnchorElement ||
                    (curr instanceof HTMLDivElement && curr.onclick) ||
                    isMuxPlayerElement(curr)
                ) {
                    trackButtonOrAnchor(curr);
                    break; //finding first ancestor is sufficient
                }
                curr = curr.parentNode as HTMLElement;
            }
        };

        window.addEventListener("click", handleButtonOrAnchorTracking, true);
        return () => window.removeEventListener("click", handleButtonOrAnchorTracking, true);
    });
};

type ButtonOrAnchorTrackingProps = {
    path: string;
    label: string | undefined;
    destination?: string;
};
