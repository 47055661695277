import { useGitpodAPI } from "@/hooks/use-gitpod-api";
import { type QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    CreateRunnerPolicyRequest,
    DeleteRunnerPolicyRequest,
    ListRunnerPoliciesRequest,
    ListRunnersRequest,
    ListRunnersRequest_Filter,
    ParseContextURLPreconditionFailureDetails,
    type Runner,
    RunnerKind,
    type RunnerPolicy,
    RunnerRole,
} from "gitpod-next-api/gitpod/v1/runner_pb";
import { type PlainMessage, toPlainMessage } from "@bufbuild/protobuf";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { keyWithPrincipal } from "@/queries/principal-key";
import { ResourceOperation, type WatchEventsResponse } from "gitpod-next-api/gitpod/v1/event_pb";
import { Code, ConnectError } from "@connectrpc/connect";
import type { PaginationResponse } from "gitpod-next-api/gitpod/v1/pagination_pb";
import type { GitpodAPI } from "@/api";
import { defaultRetry, defaultThrowOnError } from "@/queries/errors";
import { runnerConfigurationQueryKey } from "@/queries/runner-configuration-queries";

export type PlainRunner = PlainMessage<Runner>;

export function toPlainRunner(runner: Runner): PlainRunner {
    return toPlainMessage(runner);
}

export function toPlainRunnerPolicy(policy: RunnerPolicy): PlainMessage<RunnerPolicy> {
    return toPlainMessage(policy);
}

export const runnerQueryKey = {
    list: (filter: Record<string, string | number | undefined>) => keyWithPrincipal(["runners", "list", filter]),
    isUserAuthenticatedWithRunner: (runnerId?: string, repoURL?: string, sessionId?: string) =>
        keyWithPrincipal(["runners", "isUserAuthenticated", { runnerId, repoURL, sessionId }]),
    parseContext: (runnerId?: string, contextUrl?: string, tokenId?: string, keepOriginalError?: boolean) =>
        keyWithPrincipal(["runners", "parseContext", { runnerId, contextUrl, tokenId, keepOriginalError }]),
    get: (runnerId?: string) => keyWithPrincipal(["runners", { runnerId }]),
    listRunnerPolicies: (runnerId: string) => keyWithPrincipal(["runners", "policies", { runnerId }]),
};

export const handleRunnerEvent = async (api: GitpodAPI, client: QueryClient, evt: WatchEventsResponse) => {
    if (evt.operation === ResourceOperation.UPDATE) {
        const runner = await refetchRunner(api, evt.resourceId);
        setRunnerInCache(client, evt.resourceId, runner);
    } else if (evt.operation === ResourceOperation.CREATE) {
        const prefixOfAllListRunnerKeys = runnerQueryKey.list({}).slice(0, -1);
        await client.invalidateQueries({ queryKey: prefixOfAllListRunnerKeys });
    } else if (evt.operation === ResourceOperation.DELETE) {
        setRunnerInCache(client, evt.resourceId, undefined);
    }
};

const refetchRunner = async (api: GitpodAPI, runnerId: string) => {
    try {
        const { runner } = await api.runnerService.getRunner({ runnerId });
        return runner;
    } catch (error) {
        const isNotFound = error instanceof ConnectError && error.code === Code.NotFound;
        // this might happen if the runner was deleted in the meantime
        if (!isNotFound) {
            console.error("Failed to refetch runner", runnerId);
        }
    }
};

type UseParseContextURLProps = {
    enabled?: boolean;
    tokenId?: string;
};

export function useParseContextURL(runnerId?: string, contextUrl?: string, options?: UseParseContextURLProps) {
    const api = useGitpodAPI();
    const query = useQuery({
        queryKey: runnerQueryKey.parseContext(runnerId, contextUrl, options?.tokenId),
        queryFn: async () => {
            if (!contextUrl) {
                throw new Error("Context URL expected");
            }

            try {
                const { git } = await api.runnerService.parseContextURL({ contextUrl, runnerId });
                if (!git) {
                    throw new Error("Error parsing context URL");
                }
                return git;
            } catch (error) {
                if (
                    error instanceof ConnectError &&
                    (error.code === Code.FailedPrecondition || error.code === Code.PermissionDenied)
                ) {
                    const details = error.findDetails(ParseContextURLPreconditionFailureDetails);
                    throw new ConnectError("Authentication required", Code.Unauthenticated, new Headers(), details);
                }
                throw error;
            }
        },
        throwOnError: defaultThrowOnError,
        retry: defaultRetry,
        enabled: !!runnerId && !!contextUrl && (typeof options?.enabled !== "boolean" || options.enabled),
    });

    return { ...query, isLoading: query.isLoading || query.isFetching || query.isPending };
}

export const useParseContext = () => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async ({ contextUrl, runnerId }: { contextUrl: string; runnerId: string }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            try {
                const { git } = await api.runnerService.parseContextURL({ contextUrl, runnerId });
                if (!git) {
                    throw new Error("Error parsing context URL");
                }
                return git;
            } catch (error) {
                if (!(error instanceof Error)) {
                    throw new Error("Unknown error parsing context URL");
                }
            }
        },
    });
};

export type AuthenticatedWithRunnerResponse =
    | { type: "Authenticated" }
    | { type: "AuthenticationRequired"; url: string; patSupported: boolean; scmId: string };

type UseIsUserAuthenticatedWithRunnerProps = {
    refetchUntilAuthenticated?: boolean;
    sessionId?: string;
    enabled?: boolean;
};

export function useIsUserAuthenticatedWithRunner(
    runnerId?: string,
    repoURL?: string,
    options?: UseIsUserAuthenticatedWithRunnerProps,
) {
    const api = useGitpodAPI();

    const query = useQuery<AuthenticatedWithRunnerResponse>({
        queryKey: runnerQueryKey.isUserAuthenticatedWithRunner(runnerId, repoURL, options?.sessionId),
        queryFn: async () => {
            if (!repoURL) {
                throw new Error("Context URL expected");
            }

            try {
                const scmHost = new URL(repoURL).host;
                const response = await api.runnerService.checkAuthenticationForHost({
                    host: scmHost,
                    runnerId,
                });
                if (response.authenticated) {
                    return { type: "Authenticated" };
                }
                return {
                    type: "AuthenticationRequired",
                    url: response.authenticationUrl,
                    patSupported: response.patSupported,
                    scmId: response.scmId,
                };
            } catch (error) {
                const isConnectError = error instanceof ConnectError;
                if (!isConnectError) {
                    throw error;
                }

                // throw error;
                // TODO(at) reinstate rethrow after checking if Unimplemented is still an expected case

                if (error.code != Code.Unimplemented) {
                    throw error;
                }
            }

            return { type: "Authenticated" };
        },
        refetchInterval: (query) => {
            if (options?.refetchUntilAuthenticated) {
                if (query?.state?.data?.type == "AuthenticationRequired") {
                    return 2000;
                }
            }
            return false;
        },
        retry(failureCount, error) {
            if (error instanceof ConnectError) {
                switch (error.code) {
                    case Code.DeadlineExceeded:
                        return true;
                    case Code.Unauthenticated:
                    case Code.PermissionDenied:
                    case Code.NotFound:
                    case Code.InvalidArgument:
                    case Code.FailedPrecondition:
                    case Code.Internal:
                        return false;
                }
            }
            return failureCount < 3;
        },
        enabled: !!runnerId && !!repoURL && (typeof options?.enabled !== "boolean" || options.enabled),
    });
    return { ...query, isLoading: query.isLoading || query.isFetching || query.isPending };
}

type CachedRunnerList = {
    runners: PlainRunner[];
    pagination: PaginationResponse | undefined;
};

function setRunnerInCache(client: QueryClient, runnerId: string, runner?: PlainRunner) {
    client.setQueryData(runnerQueryKey.get(runnerId), runner);
    const prefixOfAllListRunnerKeys = runnerQueryKey.list({}).slice(0, -1);
    client.setQueriesData({ queryKey: prefixOfAllListRunnerKeys }, (currentData?: CachedRunnerList) => {
        if (!currentData) {
            return currentData;
        }
        if (runner) {
            return {
                ...currentData,
                // Update runner in list
                runners: currentData.runners.map((r) => (r.runnerId !== runnerId ? r : runner)),
            };
        }
        return {
            ...currentData,
            // Remove runner from list
            runners: currentData.runners.filter((r) => r.runnerId !== runnerId),
        };
    });

    // And need to make sure the runner config is reloaded
    client
        .invalidateQueries({ queryKey: runnerConfigurationQueryKey.listRunnerEnvironmentClasses(runnerId) })
        .catch(console.error);
    client
        .invalidateQueries({ queryKey: runnerConfigurationQueryKey.listRunnerSCMIntegrations(runnerId) })
        .catch(console.error);
    client
        .invalidateQueries({ queryKey: runnerConfigurationQueryKey.getRunnerConfigurationSchema(runnerId) })
        .catch(console.error);
}

export type UseListRunnersParams = {
    kind?: RunnerKind;
    creatorId?: string;
};

export const useListRunners = ({ kind, creatorId }: UseListRunnersParams) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    const query = useQuery({
        queryKey: runnerQueryKey.list({ kind, creatorId }),
        queryFn: async (): Promise<CachedRunnerList> => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { runners, pagination } = await api.runnerService.listRunners(
                new ListRunnersRequest({
                    filter: new ListRunnersRequest_Filter({
                        kinds: kind ? [kind] : undefined,
                        creatorIds: creatorId ? [creatorId] : undefined,
                    }),
                }),
            );

            return {
                runners: runners.map(toPlainRunner),
                pagination,
            };
        },
        throwOnError: defaultThrowOnError,
        retry: defaultRetry,
        enabled: !!user,
        staleTime: 1_000 * 10, // 10 seconds
        gcTime: 1_000 * 60 * 60 * 24, // 24 hours
        // even if the stale time is not over, we want to refetch on reconnect
        refetchOnReconnect: "always",
    });
    return { ...query, runners: query.data?.runners };
};

export const useRunner = (runnerId?: string) => {
    const api = useGitpodAPI();

    return useQuery<PlainRunner, Error>({
        queryKey: runnerQueryKey.get(runnerId),
        queryFn: async () => {
            if (!runnerId) {
                throw new Error("No runnerId provided");
            }

            const { runner } = await api.runnerService.getRunner({
                runnerId,
            });

            if (!runner) {
                throw new Error("Error fetching runner");
            }

            return toPlainRunner(runner);
        },
        enabled: !!runnerId,
    });
};

export const useCreateRemoteRunner = () => {
    const client = useQueryClient();
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async ({ name, region }: { name: string; region: string }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { runner, accessToken } = await api.runnerService.createRunner({
                name,
                spec: {
                    configuration: {
                        region,
                    },
                },
                kind: RunnerKind.REMOTE,
            });

            if (!runner) {
                throw new Error("Error creating runner");
            }

            return {
                runner: toPlainRunner(runner),
                accessToken,
            };
        },
        onSuccess: async () => {
            const prefixOfAllListRunnerKeys = runnerQueryKey.list({}).slice(0, -1);
            await client.invalidateQueries({ queryKey: prefixOfAllListRunnerKeys });
        },
    });
};

export const useCreateRunnerAccessToken = () => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async (runnerId: string) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { accessToken } = await api.runnerService.createRunnerToken({
                runnerId: runnerId,
            });

            return {
                accessToken,
            };
        },
    });
};

export const useUpdateRunner = () => {
    const client = useQueryClient();
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async ({ runnerId, name }: { runnerId: string; name: string }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            await api.runnerService.updateRunner({
                runnerId,
                name,
            });

            const { runner } = await api.runnerService.getRunner({
                runnerId,
            });

            if (!runner) {
                throw new Error("Error fetching runner after update");
            }
            setRunnerInCache(client, runnerId, toPlainRunner(runner));
        },
    });
};

export const useDeleteRunner = () => {
    const client = useQueryClient();
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async ({ runnerId, force = false }: { runnerId: string; force: boolean }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            return await api.runnerService.deleteRunner({
                runnerId,
                force,
            });
        },
        onSuccess: async () => {
            const prefixOfAllListRunnerKeys = runnerQueryKey.list({}).slice(0, -1);
            await client.invalidateQueries({ queryKey: prefixOfAllListRunnerKeys });
        },
    });
};

export const useListRunnerPolicies = (runnerId: string) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useQuery({
        queryKey: runnerQueryKey.listRunnerPolicies(runnerId),
        queryFn: async () => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { policies, pagination } = await api.runnerService.listRunnerPolicies(
                new ListRunnerPoliciesRequest({
                    runnerId: runnerId,
                }),
            );

            return {
                policies: policies.map(toPlainRunnerPolicy),
                pagination,
            };
        },
        throwOnError: defaultThrowOnError,
        retry: defaultRetry,
        enabled: !!user,
        staleTime: 500,
    });
};

export const useUpdateSharingSetting = (runnerId: string) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async ({ setting, groupId }: { setting: "only-me" | "anyone-in-org"; groupId: string }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            if (setting === "only-me") {
                await api.runnerService.deleteRunnerPolicy(
                    new DeleteRunnerPolicyRequest({
                        runnerId,
                        groupId,
                    }),
                );
            } else if (setting === "anyone-in-org") {
                await api.runnerService.createRunnerPolicy(
                    new CreateRunnerPolicyRequest({
                        runnerId,
                        groupId,
                        role: RunnerRole.USER,
                    }),
                );
            }
        },
    });
};
